import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image"
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import Breadscrum from "../components/Breadscrum";


const SlovnikInner = styled.section`
  min-height: calc(100vh - 200px);

  .wrapper {
    max-width: 1150px;
    width: calc(100% - 100px);
    margin: 0 auto;
    padding-top: 50px;

    @media (max-width: 899px) {
      width: calc(100% - 50px);
    }

    h1 {
      font-size: 30px;
      margin-top: 20px;
      margin-bottom: 20px;
      font-family: NewKansas;
      color: #243A58;
    }

    .obrazek {
      border-radius: 10px;
      height: 130px !important;
    }

    .perex {
      font-family: Visuelt-Regular;
      font-size: 18px;
      color: #394B5C;
      line-height: 30px;
    }

    ul {
      margin-top: 60px;
      border-top: 1px solid #bbb;
      padding-top: 60px;

      @media (max-width: 899px) {
        margin-top: 40px;
        padding-top: 40px;
      }

      li {
        list-style: none;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: 899px) {
          display: block;
          margin-bottom: 30px;
        }

        .left {
          width: calc(100% - 300px);

          @media (max-width: 899px) {
            margin-bottom: 20px;
            width: 100%;
          }
        }


        h2 {
          font-family: Visuelt-Medium;
          font-size: 22px;
          margin-bottom: 12px;

          a {
            text-decoration: none;
            font-family: Visuelt-Medium;
            color: #102137;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          line-height: 25px;
          color: #394B5C;
        }
      }
    }
  }
`;

const SlovnikPojmu = () => {

  const isPlus900 = useMediaQuery({
    query: '(min-width: 899px)'
  });

  const isLess500 = useMediaQuery({
    query: '(max-width: 500px)'
  });


  const data = useStaticQuery(graphql`{
    allContentfulSlovnicek {
      edges {
        node {
          id
          slug
          seoTitle
          seoDesc {
            seoDesc
          }
          imageSeoAlt
          h1
          content {
            content
          }
          image {
            fixed(width: 200) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }`);

  return (
    <Layout V2>




      <SEO
        title="Slovník pohřebních pojmů | Goodbye.cz"
        customTitle
        description="Obsáhlý přehled všech důležitých pojmů souvisejících s plánováním pohřbu a posledního rozloučení."
      />

      

      <SlovnikInner>
        <div className="wrapper">
          <Breadscrum
            visible={true}
            stranky={[
              {name: "Domů", url: "/"},
              {name: "Slovník pohřebních pojmů", url: "/slovnik-pojmu/"},
            ]}
          />

          <h1>Slovník pohřebních pojmů</h1>
          <p className="perex">Obsáhlý přehled všech důležitých pojmů souvisejících s plánováním pohřbu a posledního rozloučení.</p>

          <ul>
            {data.allContentfulSlovnicek.edges.map(a => {
              let e = a.node;
              return(
                <li key={e.id}>
                  <div className="left">
                    <h2><Link to={`/slovnik-pojmu/${e.slug}/`}>{e.h1}</Link></h2>
                    <p>{e.seoDesc.seoDesc}</p>
                  </div>
                  <Img className="obrazek" alt={e.imageSeoAlt} fixed={e.image.fixed} />
                </li>
              );
            })}
          </ul>
        </div>
      </SlovnikInner>
      
    </Layout>
  );
}



export default SlovnikPojmu;
